<template>
    <Setup-Step
        @yes="f => addnewItem()"
        @no="next"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="measurements"
        title="Packages">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="measurements"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.measurementName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row no-gutters>
                                    <v-btn small text @click="addnewItem(item)" icon title="Edit">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn small text @click="addnewItem(item, 7)" icon title="Edit how many units of each products fits into this package">
                                        <v-icon small>mdi-cube</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>

                    <p class="my-4">Would you like to add a package?</p>
                </v-tab-item>

                <v-tab-item v-if="newItem != null">
                    <p>
                        Standards are helpful for other companies to identify what kind of packages you deal with.
                    </p>
                        
                    <Setup-Example>
                        If you have a package identical to or the same size as a chep pallet, then it can be helpful to match it up with the Chep Standard.
                    </Setup-Example>

                    <p>Will this package follow an industry standard?</p>

                    <BT-List-Endless
                        :canSearch="false"
                        @change="setStandard"
                        itemValue="standard"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="measurement-standards"
                        returnObject
                        v-model="newItem.measurementStandard">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.standard }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-List-Endless>
                    
                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />

                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Package Name"
                        v-model="newItem.measurementName"
                        isEditing />
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Package Abbreviation"
                        v-model="newItem.abbreviation"
                        isEditing />
                    <Setup-Info>
                        An abbreviation will show as a suffix for this package in space-sensitive areas like on picking slips.
                    </Setup-Info>
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>What dimensions does this package have?</p>

                    <BT-Field-Number
                        v-model.number="newItem.height"
                        label="Height (mm)"
                        isEditing />

                    <BT-Field-Number
                        v-model.number="newItem.width"
                        label="Width (mm)"
                        isEditing />

                    <BT-Field-Number
                        v-model.number="newItem.length"
                        label="Length (mm)"
                        isEditing />

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>What about weight (in KG) and volume (in Ltr)?</p>
                    <BT-Field-Number
                        v-model.number="newItem.weight"
                        label="Weight (kg)"
                        isEditing />

                    <BT-Field-Number
                        v-model.number="newItem.volume"
                        label="Volume (ltr)"
                        isEditing />

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>What areas is this package used in?</p>
                    <Setup-Info>
                        This governs where the package can be used.  If you are in production, you can select packages that have the tag 'production'.
                    </Setup-Info>
                    <BT-Tags
                        v-model="newItem.tags"
                        label="Tags"
                        :options="['Production','Dispatch']"
                        key="0.6" />

                    <BT-Btn
                        buttonClass="primary ma-1"
                        inline
                        label="Save"
                        @click="savePackage" />
                </v-tab-item>

                <v-tab-item>
                    <p>Lastly, how many units of each product fit into this package?</p>
                    
                    <BT-Increments
                        height="55vh"
                        isEditing
                        :loadingMsg.sync="loadingMsg"
                        :measurementID="newItem.id" />

                    <BT-Btn
                        buttonClass="primary mx-1"
                        inline
                        label="Done"
                        @click="finish" />
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Measurement-Setup',
    components: {
        BTIncrements: () => import('~components/BT-Increments.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addnewItem(pkg, stg = 2) {
            this.newItem = pkg || {};

            if (pkg == null) {
                this.stage += 1;
            }
            else {
                this.stage += stg;
            }
        },
        finish() {
            this.stage = 0;
            this.refreshList = !this.refreshList;
        },
        next(nextFunc) {
            nextFunc();
        },

        async savePackage() {
            this.loadingMsg = 'Saving';
            if (this.newItem.id == null) {
                console.log(this.newItem);
                var res = await this.$BlitzIt.store.post('measurements', this.newItem);
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;
            }
            else {
                res = await this.$BlitzIt.store.patch('measurements', this.newItem);
            }
            
            this.stage += 1;
            
            this.loadingMsg = null;
        },
        async selectStandard() {
            var standard = await this.$selectItem({
                navigation: 'measurement-standards',
                itemText: 'standard'
            });

            if (standard != null) {
                this.newItem.measurementStandard = standard.standard;
                this.stage += 1;
            }
        },
        setStandard(std) {
            this.newItem.measurementStandard = std != null ? std.standard : null;
            this.newItem.measurementName = std != null ? std.measurementName : null;
            this.newItem.height = std != null ? std.height : null;
            this.newItem.width = std != null ? std.width : null;
            this.newItem.length = std != null ? std.length : null;
            this.newItem.weight = std != null ? std.weight : null;
            this.newItem.volume = std != null ? std.volume : null;

            this.stage += 1;
        }
    }
}
</script>